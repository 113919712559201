<template>
  <el-dialog
    :visible.sync="isAnalyseShow"
    width="60%"
    :before-close="beforeClose"
  >
    <div class="out-box">
      <vue-photo-zoom-pro
        class="my-vue-photo-zoom-pro"
        :hide-zoom="false"
        :out-show="true"
        :scale="6"
        :height="200"
        :url="info.result_img"
        :high-url="info.result_img"
      >
        <img class="image" :src="info.result_img" alt="暂无数据" />
      </vue-photo-zoom-pro>
      <el-table :data="tableData" style="width: 50%" height="400">
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="num" label="数量"> </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import vuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
export default {
  name: "analyse-show",
  props: {
    isAnalyseShow: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    vuePhotoZoomPro,
  },
  data() {
    return {
      tableData: [],
    };
  },
  watch: {
    isAnalyseShow(newVal) {
      this.isAnalyseShow = newVal;
      let keys = Object.keys(this.info.insect_result);
      let values = Object.values(this.info.insect_result);
      for (let i = 0; i < keys.length; i++) {
        this.tableData.push({
          name: keys[i],
          num: values[i],
        });
      }
      if (!newVal) {
        this.tableData = [];
      }
    },
  },
  methods: {
    beforeClose() {
      this.$emit("beforeClose");
    },
  },
};
</script>

<style lang="less" scoped>
.out-box {
  width: 100%;
  display: flex;
  align-items: center;
  /deep/.my-vue-photo-zoom-pro {
    width: 50%;
    top: 0;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  /deep/.el-table {
    tr {
      background-color: initial;
    }
  }
}
</style>
